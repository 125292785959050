import { json, redirect } from 'react-router-dom';

import { checkAuthLoader } from '../util/auth';
import { geocodeAddress } from '../util/maps';

import ProfileContent from '../components/users/ProfileContent';

const AddAccount = () => {
  return <ProfileContent />
}

const validateForm = (profileData, orgType) => {
  let validationErrors = [];
  if (orgType === 'NULL') {
    validationErrors.push('Please select an organisation type from the dropdown.');
  }
  if (orgType === 'gym' && (!profileData.address || profileData.address === null || profileData.address === '')) {
    validationErrors.push('Please enter an address.');
  }
  if (orgType === 'gym' && (!profileData.region || profileData.region === '')) {
    validationErrors.push('Address invalid (does not contain a county/province).');
  }
  if (!/^\d{10}$/.test(profileData.phone_number)) {
    validationErrors.push("This is not a valid phone number.");
  }
  return validationErrors;
}

export default AddAccount;

export const action = async ({ request }) => {
  const data = await request.formData();
  let profileData = {
    role: data.get('orgType') === 'gym' ? 'ROLE_GYM' : 'ROLE_PROMOTION',
    organisation: data.get('orgName'),
    address: data.get('address'),
    region: data.get('province'),
    contact_email: data.get('contactEmail'),
    area_code: data.get('areaCode'),
    phone_number: data.get('phoneNumber').replace(/^(?:\+440|440|44|\+44|0)/, '')
  }

  const validationErrors = validateForm(profileData, data.get('orgType'));
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  if (profileData.address) {
    const geoLocation = await geocodeAddress(profileData.address);
    profileData = {
      ...profileData,
      coordinates: {
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude
      }
    }
  }

  const token = await checkAuthLoader();
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/users/add-account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(profileData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  return redirect('/accounts');
}