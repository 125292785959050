import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { SocketProvider } from './context/SocketContext';
import GoogleMapsProvider from './components/ui/GoogleMapsProvider';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import RootLayout from './pages/RootLayout';
import HomePage from './pages/Home';
import ErrorPage from './pages/Error';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import RequestDeletionPage from './pages/RequestDeletion';
import LoginPage, { action as loginAction } from './pages/Login';
import RegisterPage, { action as registerAction } from './pages/Register';
import AccountPendingPage from './pages/AccountPending';
import ConfirmEmail from './pages/ConfirmEmail';
import ConfirmEmailResend, { action as confirmEmailAction } from './pages/ConfirmEmailResend';
import ConfirmEmailAction, { loader as confirmEmailLoader } from './pages/ConfirmEmailAction';
import SendResetPassword, { action as sendResetPasswordAction } from './pages/SendResetPassword';
import ResetPassword, { action as resetPasswordAction } from './pages/ResetPassword';
import AccountsPage, { loader as accountsLoader } from './pages/Accounts';
import AddAccountPage, { action as addAccountAction } from './pages/AddAccount';
import ConfirmSwitchAccount, { action as confirmSwitchAccountAction } from './pages/ConfirmSwitchAccount';
import ProfilePage, { loader as profileLoader, action as profileAction } from './pages/Profile';
import ChangePasswordModal, { action as changePasswordAction } from './pages/ChangePassword';
import ChangeEmailModal, { action as changeEmailAction } from './pages/ChangeEmail';
import ProfilePicturePage from './pages/ProfilePicture';
import InboxPage, { loader as inboxLoader } from './pages/Inbox';
import InboxPicturePage from './pages/InboxPicture';
import ExplorePage, { loader as exploreLoader } from './pages/Explore';
import PreviewMarkerModal, { loader as previewMarkerLoader } from './pages/PreviewMarker';
import GymPage, { loader as gymLoader } from './pages/Gym';
import EventPage, { loader as eventLoader } from './pages/Event'
import FighterPage, { loader as fighterLoader } from './pages/Fighter';
import FighterRequestMatchModal, { loader as fighterRequestMatchLoader } from './pages/FighterRequestMatch';
import PromotionPage, { loader as promotionLoader } from './pages/Promotion';
import DashboardPage, { loader as dashboardLoader } from './pages/Dashboard';
import NotificationsPage, { loader as notificationsLoader } from './pages/Notifications';
import ConfirmReadNotifications, { action as confirmReadNotificationsAction } from './pages/ConfirmReadNotifications';
import AddFighterPage, { action as addFighterAction } from './pages/AddFighter';
import EditFighterPage, { loader as editFighterLoader, action as editFighterAction } from './pages/EditFighter';
import DeleteFighterModal, { action as deleteFighterAction } from './pages/ConfirmDeleteFighter';
import FighterPicturePage from './pages/FighterPicture';
import EventPicturePage from './pages/EventPicture';
import AddEventPage, { action as addEventAction } from './pages/AddEvent';
import EditEventPage, { loader as editEventLoader, action as editEventAction } from './pages/EditEvent';
import DeleteEventModal, { action as deleteEventAction } from './pages/ConfirmDeleteEvent';
import SubmitFighterModal, { loader as submitFighterLoader } from './pages/SubmitFighter';
import ConfirmSubmitFighterModal, { action as confirmSubmitFighterAction } from './pages/ConfirmSubmitFighter';
import WithdrawFighterModal, { action as withdrawFighterAction } from './pages/WithdrawFighter';
import PulloutFighterModal, { action as pulloutFighterAction } from './pages/PulloutFighter';
import FightMatcherPage, { loader as fightMatcherLoader, action as fightMatcherAction } from './pages/FightMatcher';
import FightMatcherSportSelect, { loader as fightMatchSportSelectLoader } from './pages/FightMatcherSportSelect';
import RequestsPage, { loader as requestsLoader } from './pages/Requests';
import ActionMatchModal, { loader as actionMatchLoader } from './pages/ActionMatch';
import ConfirmMatchStatus, { action as confirmMatchStatusAction } from './pages/ConfirmMatchStatus';
import ConfirmWithdrawRequest, { action as confirmWithdrawRequestAction } from './pages/ConfirmWithdrawRequest';
import ConfirmTitleFight, { action as confirmTitleFightAction } from './pages/ConfirmTitleFight';
import AdminPage, { loader as adminLoader } from './pages/Admin';
import ActionUserModal, { loader as actionUserLoader } from './pages/ActionUser';
import ConfirmUserStatus, { action as confirmUserStatusAction } from './pages/ConfirmUserStatus';
import DirectoryPage from './pages/Directory';
import DirectoryTypesPage, { loader as directoryTypesLoader } from './pages/DirectoryTypes';
import DirectoryResultsPage, { loader as directoryResultsLoader } from './pages/DirectoryResults';
import AddDirectoryItem, { action as addDirectoryItemAction } from './pages/AddDirectoryItem';
import EditDirectoryItem, { loader as editDirectoryItemLoader, action as editDirectoryItemAction } from './pages/EditDirectoryItem';
import LogoutPage, { action as logoutAction } from './pages/Logout';
import { pendingLoader, checkAdminLoader, checkNoAuthLoader, checkAuthLoader, checkGymLoader, checkGymOrAdminLoader, checkPromotionLoader, checkPromotionOrAdminLoader, tokenLoader } from './util/auth';
import ConfirmDeleteInbox from './pages/ConfirmDeleteInbox';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: 'login',
        element: <LoginPage />,
        action: loginAction,
        loader: checkNoAuthLoader,
        children: [
          {
            path: 'request-password-reset',
            element: <SendResetPassword />,
            action: sendResetPasswordAction,
            loader: checkNoAuthLoader
          }
        ]
      },
      {
        path: 'reset-password/:token',
        element: <ResetPassword />,
        action: resetPasswordAction,
        loader: checkNoAuthLoader
      },
      {
        path: 'register',
        element: <RegisterPage />,
        action: registerAction,
        loader: checkNoAuthLoader
      },
      {
        path: 'email-unconfirmed/:email',
        element: <ConfirmEmail />,
        loader: checkNoAuthLoader,
        children: [
          {
            path: 'resend',
            element: <ConfirmEmailResend />,
            action: confirmEmailAction,
            loader: checkNoAuthLoader
          }
        ]
      },
      {
        path: 'confirm-email/:id',
        element: <ConfirmEmailAction />,
        loader: confirmEmailLoader
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />
      },
      {
        path: 'request-deletion',
        element: <RequestDeletionPage />
      },
      {
        path: 'pending',
        element: <AccountPendingPage />,
        loader: checkNoAuthLoader
      },
      {
        path: 'profile',
        element: <ProfilePage />,
        action: profileAction,
        loader: profileLoader,
        children: [
          {
            path: 'change-password',
            element: <ChangePasswordModal />,
            action: changePasswordAction,
            loader: checkAuthLoader
          },
          {
            path: 'change-email',
            element: <ChangeEmailModal />,
            action: changeEmailAction,
            loader: checkAuthLoader
          }
        ]
      },
      {
        path: 'profile/upload-image',
        element: <ProfilePicturePage />,
        loader: checkAuthLoader
      },
      {
        path: 'accounts',
        element: <AccountsPage />,
        loader: accountsLoader,
        children: [
          {
            path: 'switch/:id',
            element: <ConfirmSwitchAccount />,
            action: confirmSwitchAccountAction,
            loader: checkAuthLoader
          }
        ]
      },
      {
        path: 'accounts/add',
        element: <AddAccountPage />,
        loader: checkAuthLoader,
        action: addAccountAction
      },
      {
        path: 'inbox',
        element: <InboxPage />,
        loader: inboxLoader,
        children: [
          {
            path: ':inboxId/delete',
            element: <ConfirmDeleteInbox />,
            loader: checkAuthLoader
          }
        ]
      },
      {
        path: 'inbox/:inboxId/upload-image',
        element: <InboxPicturePage />,
        loader: checkAuthLoader
      },
      {
        path: 'explore',
        element: <ExplorePage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : exploreLoader,
        children: [
          {
            path: 'preview/:type/:id',
            element: <PreviewMarkerModal />,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : previewMarkerLoader
          }
        ]
      },
      {
        path: 'gym/:gymId',
        element: <GymPage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : gymLoader
      },
      {
        path: 'gym/:gymId/add-fighter',
        element: <AddFighterPage />,
        action: addFighterAction,
        loader: checkAdminLoader
      },
      {
        path: 'event/:eventId',
        element: <EventPage />,
        loader: eventLoader,
        children: [
          {
            path: 'delete',
            element: <DeleteEventModal />,
            action: deleteEventAction,
            loader: checkPromotionLoader
          },
          {
            path: 'submit-fighter',
            element: <SubmitFighterModal />,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : submitFighterLoader,
            children: [
              {
                path: 'confirm/:sport/:fighterId',
                element: <ConfirmSubmitFighterModal />,
                action: confirmSubmitFighterAction,
                loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkGymLoader
              }
            ]
          },
          {
            path: 'withdraw-fighter/:fighterId',
            element: <WithdrawFighterModal />,
            action: withdrawFighterAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkGymLoader
          },
          {
            path: 'pullout-fighter/:fightId',
            element: <PulloutFighterModal />,
            action: pulloutFighterAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkGymLoader
          },
          {
            path: ':fightId/delete',
            element: <ConfirmWithdrawRequest />,
            action: confirmWithdrawRequestAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkPromotionLoader
          },
          {
            path: ':fightId/title/:status',
            element: <ConfirmTitleFight />,
            action: confirmTitleFightAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkPromotionLoader
          }
        ]
      },
      {
        path: 'event/:eventId/fight-matcher',
        element: <FightMatcherSportSelect />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : fightMatchSportSelectLoader,
      },
      {
        path: 'event/:eventId/fight-matcher/sport/:sport',
        element: <FightMatcherPage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : fightMatcherLoader,
        action: fightMatcherAction
      },
      {
        path: 'event/:eventId/fight-matcher/sport/:sport/:fighterAId',
        element: <FightMatcherPage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : fightMatcherLoader,
        action: fightMatcherAction
      },
      {
        path: 'fighter/:fighterId',
        element: <FighterPage />,
        loader: fighterLoader,
        children: [
          {
            path: 'delete',
            element: <DeleteFighterModal />,
            action: deleteFighterAction,
            loader: checkGymOrAdminLoader
          },
          {
            path: 'request-match',
            element: <FighterRequestMatchModal />,
            loader: fighterRequestMatchLoader
          }
        ]
      },
      {
        path: 'promotion/:promotionId',
        element: <PromotionPage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : promotionLoader
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
        loader: dashboardLoader,
      },
      {
        path: 'dashboard/notifications',
        element: <NotificationsPage />,
        loader: notificationsLoader,
        children: [
          {
            path: 'read/:id',
            element: <ConfirmReadNotifications />,
            loader: checkAuthLoader,
            action: confirmReadNotificationsAction
          }
        ]
      },
      {
        path: 'dashboard/add-fighter',
        element: <AddFighterPage />,
        action: addFighterAction,
        loader: checkGymLoader
      },
      {
        path: 'dashboard/edit-fighter/:fighterId',
        element: <EditFighterPage />,
        loader: editFighterLoader,
        action: editFighterAction
      },
      {
        path: 'dashboard/edit-fighter/:fighterId/upload-image',
        element: <FighterPicturePage />,
        loader: checkGymLoader
      },
      {
        path: 'dashboard/add-event',
        element: <AddEventPage />,
        action: addEventAction,
        loader: checkPromotionLoader
      },
      {
        path: 'dashboard/edit-event/:eventId',
        element: <EditEventPage />,
        loader: editEventLoader,
        action: editEventAction
      },
      {
        path: 'dashboard/edit-event/:eventId/upload-image',
        element: <EventPicturePage />,
        loader: checkPromotionLoader
      },
      {
        path: 'dashboard/requests',
        element: <RequestsPage />,
        loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : requestsLoader,
        children: [
          {
            path: ':fightId',
            element: <ActionMatchModal />,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : actionMatchLoader,
            children: [
              {
                path: 'confirm/:status',
                element: <ConfirmMatchStatus />,
                action: confirmMatchStatusAction,
                loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkAuthLoader
              }
            ]
          },
          {
            path: ':fightId/delete',
            element: <ConfirmWithdrawRequest />,
            action: confirmWithdrawRequestAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkPromotionLoader
          },
          {
            path: ':fightId/title/:status',
            element: <ConfirmTitleFight />,
            action: confirmTitleFightAction,
            loader: process.env.REACT_APP_SITE_PENDING === 'true' ? pendingLoader : checkPromotionLoader
          }
        ]
      },
      {
        path: '/directory',
        element: <DirectoryPage />,
        loader: checkPromotionOrAdminLoader
      },
      {
        path: '/directory/region/:region',
        element: <DirectoryTypesPage />,
        loader: directoryTypesLoader
      },
      {
        path: '/directory/region/:region/type/:type',
        element: <DirectoryResultsPage />,
        loader: directoryResultsLoader
      },
      {
        path: '/directory/add',
        element: <AddDirectoryItem />,
        action: addDirectoryItemAction,
        loader: checkAdminLoader
      },
      {
        path: '/directory/:id/edit',
        element: <EditDirectoryItem />,
        loader: editDirectoryItemLoader,
        action: editDirectoryItemAction
      },
      {
        path: 'admin',
        element: <AdminPage />,
        loader: adminLoader,
        children: [
          {
            path: ':userId',
            element: <ActionUserModal />,
            loader: actionUserLoader,
            children: [
              {
                path: 'confirm/:status',
                element: <ConfirmUserStatus />,
                action: confirmUserStatusAction,
                loader: checkAdminLoader
              }
            ]
          }
        ]
      },
      {
        path: 'logout',
        element: <LogoutPage />,
        action: logoutAction,
        loader: checkAuthLoader
      }
    ]
  }
])

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const setupPushNotifications = async () => {
      // Check if the platform is Android or iOS
      if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
        // Request permission for push notifications
        const permissionResult = await PushNotifications.requestPermissions();
        if (permissionResult.receive === 'granted') {
          // Register with FCM
          await PushNotifications.register();

          // Listen for successful token registration
          PushNotifications.addListener('registration', async (token) => {
            console.log('Push registration success, token:', token.value);

            // Check if the user is logged in by retrieving the JWT
            const jwtToken = await tokenLoader();
            if (jwtToken) {
              // Check if the token already exists in localStorage
              const storedToken = localStorage.getItem('fcm_token');
              if (storedToken !== token.value) {
                // If the token is new, send it to the backend and store it in localStorage
                await sendTokenToBackend(token.value, jwtToken);
              }
            } else {
              console.log('User is not logged in, skipping FCM token update');
            }
          });

          // Handle registration errors
          PushNotifications.addListener('registrationError', (error) => {
            console.error('Error on registration:', error);
          });
        }
      }
    };

    // Call the setup function
    setupPushNotifications();
  }, []);

  // Function to send token to the backend
  const sendTokenToBackend = async (fcmToken, jwtToken) => {
    console.log("Sending FCM token to backend: " + fcmToken);
    try {
      const authUrl = process.env.REACT_APP_AUTH_URL;
      const response = await fetch(`${authUrl}/api/v1/users/fcm/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`, // JWT token from tokenLoader
        },
        body: JSON.stringify({ fcm_token: fcmToken }),
      });
      if (response.ok) {
        console.log('Token sent to backend successfully');
        localStorage.setItem('fcm_token', fcmToken);
      } else {
        console.error('Failed to send token to backend');
      }
    } catch (error) {
      console.error('Error sending token to backend:', error);
    }
  };


  return <SocketProvider>
    <GoogleMapsProvider>
      <RouterProvider router={router} />;
    </GoogleMapsProvider>
  </SocketProvider>
}

export default App;
