
export const geocodeAddress = async (address) => {
  try {
    const authUrl = process.env.REACT_APP_AUTH_URL
    const response = await fetch(`${authUrl}/api/v1/proxy/loqate/geocode/${address}/GBR`);
  
    const resData = await response.json();
    return resData
  } catch (error) {
    console.error('Error fetching addresses:', error);
  }
};