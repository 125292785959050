import { json, Outlet, redirect } from 'react-router-dom';

import { decodeToken, checkAuthLoader } from '../util/auth';
import { geocodeAddress } from '../util/maps';

import ProfileContent from '../components/users/ProfileContent';

const xPlatformValidUrls = [
  "https://x.com/",
  "https://twitter.com/",
  "https://www.x.com/",
  "https://www.twitter.com/"
];

const instagramValidUrls = [
  "https://instagram.com/",
  "https://www.instagram.com/"
];

const facebookValidUrls = [
  "https://facebook.com/",
  "https://www.facebook.com/"
];

const youtubeValidUrls = [
  "https://youtube.com/",
  "https://www.youtube.com/"
];

const tapologyValidUrls = [
  "https://tapology.com/",
  "https://www.tapology.com/"
];

const Profile = () => {
  return <>
    <ProfileContent />
    <Outlet />
  </>
}

const validateForm = (profileData, role) => {
  let validationErrors = [];
  if (role === 'ROLE_GYM') {
    if (!profileData.address || profileData.address === null || profileData.address === '') {
      validationErrors.push('Address must be selected. Please search and select from the suggestions.');
    }
    if (!profileData.region || profileData.region === '') {
      validationErrors.push('Address invalid (does not contain a county/province).');
    }
  }
  if (profileData.x_platform && !xPlatformValidUrls.some(url => profileData.x_platform.startsWith(url))) {
    validationErrors.push("X Platform link is invalid.");
  }
  if (profileData.instagram && !instagramValidUrls.some(url => profileData.instagram.startsWith(url))) {
    validationErrors.push("Instagram link is invalid.");
  }
  if (profileData.facebook && !facebookValidUrls.some(url => profileData.facebook.startsWith(url))) {
    validationErrors.push("Facebook link is invalid.");
  }
  if (profileData.youtube && !youtubeValidUrls.some(url => profileData.youtube.startsWith(url))) {
    validationErrors.push("YouTube link is invalid.");
  }
  if (profileData.tapology_url && !tapologyValidUrls.some(url => profileData.tapology_url.startsWith(url))) {
    validationErrors.push("Tapology link is invalid.");
  }
  if (!/^\d{10}$/.test(profileData.phone_number)) {
    validationErrors.push("This is not a valid phone number.");
  }
  return validationErrors;
}

export default Profile;

export const action = async ({ request }) => {
  const data = await request.formData();
  let profileData = {
    organisation: data.get('orgName'),
    address: data.get('address'),
    region: data.get('province'),
    contact_email: data.get('contactEmail'),
    area_code: data.get('areaCode'),
    phone_number: data.get('phoneNumber').replace(/^(?:\+440|440|44|\+44|0)/, '').replace(/\s+/g, ''),
    x_platform: data.get('xPlatform').trim(),
    facebook: data.get('facebook').trim(),
    instagram: data.get('instagram').trim(),
    youtube: data.get('youtube').trim(),
    tapology_url: data.get('tapology').trim(),
    email_notifications: data.get('emailNotifications'),
    sms_notifications: data.get('smsNotifications')
  }

  const token = await checkAuthLoader();
  const role = decodeToken(token).authorities[0];
  const validationErrors = validateForm(profileData, role);
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  if (profileData.address) {
    const geoLocation = await geocodeAddress(profileData.address);
    profileData = {
      ...profileData,
      coordinates: {
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude
      }
    }
  }

  const userId = decodeToken(token).activeRoleUserId;
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/accounts/update/' + userId, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(profileData)
  });

  const resData = await response.json();

  if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 409) {
    return resData;
  }

  if (!response.ok) {
    throw json({ message: resData.message }, { status: 500 });
  }

  // return { message: 'Profile Updated!', success: true }
  return redirect('/dashboard');
}

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return token;
  }
  const userId = decodeToken(token).activeRoleUserId;
  const options = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const authUrl = process.env.REACT_APP_AUTH_URL
  const response = await fetch(authUrl + '/api/v1/accounts/profile/id/' + userId, options);
  const resData = await response.json();
  const data = {
    orgName: resData.organisation,
    address: resData.address,
    region: resData.region,
    contactEmail: resData.contact_email,
    areaCode: resData.area_code,
    phoneNumber: resData.phone_number,
    xPlatform: resData.x_platform,
    facebook: resData.facebook,
    instagram: resData.instagram,
    youtube: resData.youtube,
    tapology: resData.tapology_url,
    imageUrl: resData.image_url,
    emailNotifications: resData.email_notifications,
    smsNotifications: resData.sms_notifications
  }
  return data;
}