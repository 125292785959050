import { useState, useEffect } from 'react';
import {
  Form,
  useLocation,
  useLoaderData,
  useActionData,
  useNavigation,
  Link
} from 'react-router-dom';
import moment from 'moment-timezone';

import styles from './EventForm.module.css';

const EventForm = () => {

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return {
      date: convertedTime.toDate(),
      time: convertedTime.format('HH:mm'),
      day: convertedTime.format('yyyy-MM-DD')
    };
  }

  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const data = useLoaderData();
  const actionData = useActionData()
  const location = useLocation();
  const isAddEvent = location.pathname === '/dashboard/add-event';


  const [name, setName] = useState(data?.name || "");
  const [postcode, setPostcode] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(data?.address || "");
  const [date, setDate] = useState(data?.date_time ? convertToTimezone(data.date_time).day : convertToTimezone(new Date()).day);
  const [time, setTime] = useState(data?.date_time ? convertToTimezone(data.date_time).time : convertToTimezone(new Date()).time);
  const [sports, setSports] = useState(data?.sports || []);
  const [sameWeighInDate, setSameWeighInDate] = useState(true);
  const [weighInDate, setWeighInDate] = useState(data?.weigh_in ? convertToTimezone(data.weigh_in).day : convertToTimezone(new Date()).day);
  const [weighInTime, setWeighInTime] = useState(data?.weigh_in ? convertToTimezone(data.weigh_in).time : convertToTimezone(new Date()).time);
  const [weighInType, setWeighInType] = useState(data?.weigh_in_type || "VIDEO")
  const [tapologyUrl, setTapologyUrl] = useState(data?.tapology_url || '');

  const sportsOptions = [
    { value: 'MMA', label: 'MMA' },
    { value: 'BOXING', label: 'Boxing' },
    { value: 'K1', label: 'K1' },
    { value: 'MUAY_THAI', label: 'Muay Thai' },
    { value: 'GRAPPLING', label: 'Grappling' }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isAddEvent) {
      setSameWeighInDate(true);
    } else {
      setSameWeighInDate(convertToTimezone(data.date_time).day === convertToTimezone(data.weigh_in).day);
    }
  }, [isAddEvent, data.date_time, data.weigh_in])

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.target.closest('.addressList')) {
        event.stopPropagation();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  if (actionData) {
    window.scrollTo(0, 0);
  }

  const handlePostcodeChange = async (e) => {
    const value = e.target.value;
    setPostcode(value);
    if (value.length > 3) {
      try {
        const authUrl = process.env.REACT_APP_AUTH_URL
        const response = await fetch(`${authUrl}/api/v1/proxy/loqate/find/${e.target.value}`);
        const resData = await response.json();
        setSuggestions(resData.Items);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  }

  const handleSuggestionsClick = async (suggestion) => {
    if (suggestion.Type === 'Address') {
      try {
        const authUrl = process.env.REACT_APP_AUTH_URL
        const response = await fetch(`${authUrl}/api/v1/proxy/loqate/retrieve/${suggestion.Id}`);
        const resData = await response.json();
        setSelectedAddress(resData.label);
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    } else {
      try {
        const authUrl = process.env.REACT_APP_AUTH_URL
        const response = await fetch(`${authUrl}/api/v1/proxy/loqate/find/${suggestion.Text}/${suggestion.Id}`);
        const resData = await response.json();
        setSuggestions(resData.Items);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    }
  }

  const handleResetAddress = () => {
    setPostcode('');
    setSuggestions([]);
    setSelectedAddress('');
  }

  const handleSportsChange = (event) => {
    const selectedSport = event.target.value;
    setSports((prevSports) =>
      prevSports.includes(selectedSport)
        ? prevSports.filter((sport) => sport !== selectedSport)
        : [...prevSports, selectedSport]
    );
  };

  const handleSameWeighInDateChange = (event) => {
    const isSame = event.target.value === 'true';
    setSameWeighInDate(isSame);
    if (!isSame) {
      setWeighInDate(date);
    }
  }


  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <Form method="post" className={styles.form}>
          <img className={styles.authlogo} src={process.env.PUBLIC_URL + '/authlogo.png'} alt="Logo" />
          <h1>{isAddEvent ? 'Add Event' : 'Edit Event'}</h1>
          {actionData && actionData.errors && (
            <div className={styles.errors}>
              <ul>
                {Object.values(actionData.errors).map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {actionData && actionData.message &&
            <div className={actionData.success ? styles.success : styles.errors}>
              <p>{actionData.message}</p>
            </div>
          }

          <p>
            <label htmlFor="name">Name</label>
            <input id="name" type="text" name="name" value={name} onChange={(event) => setName(event.target.value)} required />
          </p>

          {!isAddEvent && <p>
            <label>Event Picture</label>
            {data.image_url ? (
              <img className={styles.eventPicture} src={`${data.image_url}?timestamp=${new Date().getTime()}`} alt="EventPicture" />
            ) : null}
            <Link className={styles.link} to='upload-image'>
              {data.image_url ? "Click here to change the event picture" : "Click here to upload an event picture"}
            </Link>
          </p>}

          {selectedAddress === '' &&
            <>
              <label htmlFor="postcode">Search Address</label>
              <input
                id="postcode"
                type="text"
                name="postcode"
                placeholder="Start typing your address or postcode..."
                value={postcode}
                onChange={handlePostcodeChange}
                autoComplete="off"
              />
              {suggestions.length > 0 && (
                <ul className={styles.addressList}>
                  {suggestions.map((suggestion) => (
                    <li key={suggestion.Id} onClick={() => handleSuggestionsClick(suggestion)}>
                      {suggestion.Text} - {suggestion.Description}
                    </li>
                  ))}
                </ul>
              )}
            </>
          }
          {selectedAddress !== '' &&
            <>
              <p>
                <label htmlFor="address">Address</label>
                <input id="address" type="text" name="address" value={selectedAddress.replace(/\n/g, ', ')} readOnly />
              </p>
              <p className={styles.resetaddress} onClick={handleResetAddress}>Click here to search for another address</p>
            </>
          }
          <hr />
          <p>
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={(event) => setDate(event.target.value)}
              required
            />
          </p>

          <p>
            <label htmlFor="time">Time (24hr)</label>
            <input
              id="time"
              type="time"
              name="time"
              value={time}
              onChange={(event) => setTime(event.target.value)}
              required
            />
          </p>

          <div>
            <label htmlFor="sports">Sports</label>
            <input type="hidden" name="sports" value="" />
            <div className={styles.sportsContainer}>
              {sportsOptions.map((sport) => (
                <div key={sport.value} className={styles.sportItem}>
                  <input
                    type="checkbox"
                    id={sport.value}
                    name="sports"
                    value={sport.value}
                    checked={sports.includes(sport.value)}
                    onChange={handleSportsChange}
                  />
                  <label htmlFor={sport.value}>{sport.label}</label>
                </div>
              ))}
            </div>
          </div>

          <p>
            <label htmlFor="sameWeighInDate">Same Day Weigh In?</label>
            <select id="sameWeighInDate" name="sameWeighInDate" value={sameWeighInDate} onChange={handleSameWeighInDateChange} required>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </p>

          {!sameWeighInDate &&
            <p>
              <label htmlFor="weighInDate">Weigh In Date</label>
              <input
                type="date"
                id="weighInDate"
                name="weighInDate"
                value={weighInDate}
                onChange={(event) => setWeighInDate(event.target.value)}
                required
              />
            </p>
          }

          <p>
            <label htmlFor="weighInTime">Weigh In Time (24hr)</label>
            <input
              id="weighInTime"
              type="time"
              name="weighInTime"
              value={weighInTime}
              onChange={(event) => setWeighInTime(event.target.value)}
              required
            />
          </p>

          <p>
            <label htmlFor="weighInType">Weigh In Type</label>
            <select id="weighInType" name="weighInType" value={weighInType} onChange={(event) => setWeighInType(event.target.value)} required>
              <option value="IN_PERSON">In Person</option>
              <option value="VIDEO">Video</option>
            </select>
          </p>

          <p>
            <label htmlFor="tapologyUrl">Tapology Link - Optional</label>
            <input id="tapologyUrl" type="text" name="tapologyUrl" value={tapologyUrl} onChange={(event) => setTapologyUrl(event.target.value)} />
          </p>

          <div className={styles.actions}>
            <button disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : isAddEvent ? 'Add Event' : 'Update Event'}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default EventForm;
