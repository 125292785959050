import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import MainNavigation from '../components/ui/MainNavigation';
import NotificationsCard from '../components/ui/NotificationsCard';

const RootLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPrivacy = location.pathname.includes('/privacy-policy');
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {

      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        const data = notification.notification.data;
        if (data && data.open_url) {
          if (data.open_url.startsWith('/')) {
            navigate(data.open_url);
          } else {
            navigate('/' + data.open_url);
          }
        }
      });
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      {!isPrivacy && <MainNavigation />}
      <NotificationsCard />
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default RootLayout;

