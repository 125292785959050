import { useEffect } from 'react';
import { useLoaderData, useRouteLoaderData, Link } from 'react-router-dom';
import AccountCard from './AccountCard';
import { decodeToken } from '../../util/auth'

import styles from './AccountsContent.module.css';

const AccountsContent = () => {
  const data = useLoaderData();
  const token = useRouteLoaderData('root');
  const decodedToken = decodeToken(token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div className={styles.accounts}>
    <h1>Your Accounts</h1>
    <h3>Own another gym or promotion? Add new accounts here...</h3>
    <Link className={styles.addLink} to="add">
      Add Account
    </Link>
    <ul className={styles.accountsList}>
      {data.map(account =>
      (
        <li key={account.id} className={styles.accountsListItem}>
          <AccountCard account={account} tokenSub={decodedToken.activeRoleUserId} />
        </li>
      )
      )}
    </ul>
    <Link className={styles.deleteAccountLink} to="/request-deletion">
      Delete User?
    </Link>
  </div>
}

export default AccountsContent;